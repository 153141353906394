.App {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  color: #e5e5e5;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
}

.navbar .navbar-nav .nav-link:hover {
  color: #fca311;
}

.navbar .navbar-nav .nav-link {
  color: #ffffff;
}

#newhover {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
} 

.custom {
  background-color:#000000 !important;
  padding: 0;
  margin: 0;
}
#newhover:hover {
  color:  #fca311;;
} 

.welcome {
  box-shadow: 2px 2px 10px #14213d;
  width: 80%;
  display: inline-block
}

.pageContainer {
  flex: 1
}

.brand {
  height: 90px;
}

@media only screen and (max-width: 576px) {
  .navbar .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 1rem;
  }

  .map {
    height: 300px;
  }

  .welcome {
    width: 95%;
  }

  .brand {
    height: 70px;
    display: inline-block;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 1rem;
  }
  .welcome {
    width: 80%;
  }
}

@media only screen and (min-width: 1400px) {
  .navbar .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 1.5rem;
  }

  .welcome {
    width: 50%;
  }
}
